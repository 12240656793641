import { Component, InputSignal, OnInit, WritableSignal, inject, input, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AccountService } from '../../account.service';
import { tap } from 'rxjs';
import { DeliveriesService } from '../../../settings/account/deliveries/deliveries.service';
import { UpdateDeliveryInfoPayload } from '../../../settings/account/deliveries/intarfaces';
import { DeliveryOptionsResponseItem } from '../../types/account.types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-delivery-day-selection',
    imports: [
        MatFormFieldModule,
        MatSelectModule
    ],
    templateUrl: './delivery-day-selection.component.html',
    styleUrl: './delivery-day-selection.component.scss'
})
export class DeliveryDaySelectionComponent implements OnInit {

  private accountService = inject(AccountService);
  private deliveriesService = inject(DeliveriesService);
  private activeModal = inject(NgbActiveModal);

  zoneId: InputSignal<number> = input(0, { alias: 'zoneId' });

  deliveryOptions: WritableSignal<DeliveryOptionsResponseItem[]> = signal([]);
  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);

  ngOnInit(): void {
    this.accountService.getDeliveryOptions(this.zoneId()).pipe(
      tap((options) => this.deliveryOptions.set(options ?? []))
    ).subscribe()
  }

  saveDaliveryDay() {
    const payload = this.setUpDeliveryInfoPayload();
    this.deliveriesService.updateDeliveryInfo(payload).pipe(
      tap(() => this.activeModal.close(true))
    ).subscribe();
  }

  private setUpDeliveryInfoPayload(): UpdateDeliveryInfoPayload {
    return {
      dayId: this.selectedDeliveryDay()?.id,
      timeWindowId: null,
      shouldAffectCustomerInfo: true
    } as UpdateDeliveryInfoPayload
  }

  setSelectedDeliveryDay(value: number) {
    this.selectedDeliveryDay.set(this.deliveryOptions().find(o => o.id === value) ?? null);
  }
}
