import { Component, computed, inject, input, signal, Signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SignalsStoreService } from '../../signals-store.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { formatSubscriptionDate } from '../../utils/formatting';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ProductSubscription } from '../modal-content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { Item } from '../../types/common.types';

@Component({
  selector: 'app-product-subscription',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    CommonModule,
    MatDatepickerModule
  ],
  templateUrl: './product-subscription.component.html',
  styleUrl: './product-subscription.component.scss'
})
export class ProductSubscriptionComponent {

  //#region Services

  #activeModal = inject(NgbActiveModal);

  #signalsStoreService = inject(SignalsStoreService);

  //#endregion

  //#region Inputs / Outputs

  productSubsInfo = input.required<ProductSubscription | undefined>();

  //#endregion

  //#region Properties

  currentDate = new Date();

  initiateSubscriptionImmediatly = signal(true);

  selectedDateSubscription!: any;

  selectedFrequency!: Item | undefined;

  frequencies: Signal<any[]> = computed(() => {
    const freqs = this.#signalsStoreService.frequencies() || []
    if (freqs.length) this.selectedFrequency = freqs[0];

    return freqs;
  });

  //#endregion

  //#region Methods

  onDateChange(event: any) {
    const selectedDate: Date = event.value;
    const formattedDate: string = formatSubscriptionDate(selectedDate);

    this.selectedDateSubscription = formattedDate;
  }

  dateFilter = (date: Date | null) => {
    if (!date) {
      return false;
    }

    if (!this.productSubsInfo()?.deliveryDay) return true;

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const index = daysOfWeek.indexOf(this.productSubsInfo()?.deliveryDay as string);
    return date.getDay() === index;
  }

  cancel = () => this.#activeModal.close({ isCancel: true });

  continue = () => this.#activeModal.close({
    isCancel: false,
    isContinue: true,
    initiateSubscriptionImmediatly: this.initiateSubscriptionImmediatly(),
    selectedDateSubscription: this.selectedDateSubscription,
    selectedFrequency: this.selectedFrequency
  });

  subscribe = (event: Event) => {

    this.#activeModal
      .close({
        isCancel: false,
        event,
        initiateSubscriptionImmediatly: this.initiateSubscriptionImmediatly(),
        selectedDateSubscription: this.selectedDateSubscription,
        selectedFrequency: this.selectedFrequency
      });
  }

  onChangeFrequency(target: any) {
    const frequencyId = target.value
    this.selectedFrequency = this.frequencies().find(frequency => frequency.id === frequencyId);
  }

  //#endregion
}
