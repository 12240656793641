import { Component, Input, computed, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalContentData } from '../modal-content';
import { SignalsStoreService } from '../../signals-store.service';

@Component({
  selector: 'app-welcome-message',
  imports: [],
  templateUrl: './welcome-message.component.html',
  styleUrl: './welcome-message.component.scss'
})
export class WelcomeMessageComponent {

  private signalsStoreService = inject(SignalsStoreService);

  activeModal = inject(NgbActiveModal);
  router = inject(Router);

  isInShopComponent = computed(() => this.signalsStoreService.getIsInShopComponent());

  @Input() modalContentData!: ModalContentData;

  goTo() {
    if (!this.isInShopComponent())
      this.router.navigate([`/shop`]);
    this.close()
  }

  close() {
    this.activeModal.close();
  }
}
