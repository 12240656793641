export enum FIREBASE_COLLECTIONS {
  ORDERS = 'orders',
  SIGNUP_STEP = 'signUpStep',
  PREFERENCES = 'preferences'
}

export enum LOCALSTORAGE_KEYS {
  CART = 'cart',
  ORDER = 'order',
  SESSION = 'session',
  ANONYMOUS_ADDRESS = 'anonymousAddress',
  SELECTED_CATEGORY = 'selectedCategory',
  NEW_ACCOUNT = 'newAccount',
  GOOGLEPLACERESULT = 'googlePlaceObject',
  MARKETING_REFERRAL = 'marketingReferral',
  FIRST_LOGIN = 'firstLogin',
  SIGNUP_CUSTOM_BOX = 'signupCustomBox',
  SELECTED_DELIVERY_PREFERENCE = 'selectedDeliveryPreference',
  SELECTED_PICKUP_OPTION = 'selectedPickUpOption',
  ROUTE_TO_NAVIGATE_AFTER_LOGIN = 'routeToNavigateAfterLogin',
  FROM_LOGIN = 'fromLogin',
  PERMISSIONS = 'permissions',
  SIGNUP_SELECTED_MEMBERSHIP = 'signupSelectedDelivery',
  NO_PASSWORD_USER_EMAIL = 'noPasswordUserEmail',
  MAINTENANCE_MODE = 'maintenanceMode',
  MAINTENANCE_SCHEDULED = 'maintenanceScheduled',
  MAINTENANCE_TITLE = 'maintenanceTitle',
  MAINTENANCE_MESSAGE = 'maintenanceMessage',
  SIGNUP_CURRENT_STEP = 'signupCurrentStep',
  SIGNUP_SHOULD_UPDATE_ORDER = 'signupShouldUpdateOrder',
  REPLACEMENT_VARIANTS_ID = 'replacementVariantsId',
  WELCOME_CUSTOM_BOX = 'welcomeCustomBox',
  CATEGORY_TO_SELECT_ON_SHOP = 'categoryToSelectOnShop',
  PRELOADED_EMAIL = 'preloadedEmail',
  SUBMIT_ORDER_FROM_CUSTOM_BOX = 'SUBMIT_ORDER_FROM_CUSTOM_BOX'
}
