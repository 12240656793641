<div class="row mt-5">
  <section class="col">
    <h4>Credit card number<b class="text-danger">*</b></h4>
    <p>**** **** **** {{data?.creditCardLastNumbers}}</p>
  </section>
</div>

<div class="row">
  <section class="col">
    <div class="card w-100 px-4 pt-4">
      <div class="card-body">
        <h6 class="card-subtitle mb-2 text-body-secondary">Summary</h6>
        <h5 class="card-title">{{membershipName()}}</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Renews {{membershipFrequency()}} {{membershipPrice() | currency}}</p>
        <p class="card-text">One time deposit {{depositValue() | currency}}</p>
      </div>
      <div class="card-body text-center border-top">
        <h4>Today's total {{total() | currency}}</h4>
      </div>
    </div>
  </section>
</div>

<div class="row mt-2 g-3">
  <div class="col col-md-12 d-grid">
    <button type="button" class="btn btn-primary" (click)="closeModal(true)">Subscribe</button>
  </div>
  <div class="col col-md-12 d-grid">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal(false)">Cancel</button>
  </div>
</div>