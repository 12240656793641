import { Component, inject } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Location } from '@angular/common';
import { LocalStorageService } from '../../local-storage.service';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../../constants/databases';
import { SignUpSteps } from '../../types/common.enums';
import { FirebaseCrudService } from '../../firebase-crud.service';
import { Session } from '../../types/session.type';
import { tap } from 'rxjs';

@Component({
  selector: 'app-pending-payment-method',
  providers: [provideNativeDateAdapter()],
  imports: [],
  template: `
    <div class="container mt-3">
      <div class="row">
        <div class="col mb-3 text-center">
          <span class="h5-regular">
          It appears that you have already started the sign-up process, but have not
          finished it yet. Don't worry, we've saved your progress! To continue right where
          you left off, please complete the remaining steps of the sign-up process below.
          </span>
        </div>
      </div>

      <div class="row mt-0 mb-2 mt-3 justify-content-center">
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-primary"
            (click)="reloadPage('/signup')"
          >
            CONTINUE SIGN UP
          </button>
        </div>
      </div>
    </div>
  `
})
export class PendingPaymentMethodComponent {
  private localStorageService = inject(LocalStorageService);
  private location = inject(Location);
  private firebaseCrudService = inject(FirebaseCrudService);

  reloadPage(route: string) {

    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!sessionStored)
      return;

    this.firebaseCrudService
      .getById(FIREBASE_COLLECTIONS.SIGNUP_STEP, sessionStored.accountInfo.id.toString(), (res => {
        this.localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_CURRENT_STEP, res?.stepIndex ?? SignUpSteps.paymentInformation);
        this.location.replaceState(route);
        window.location.reload();
      }));
  }
}
