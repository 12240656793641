import { Component, inject } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../../constants/databases';
import { FirebaseCrudService } from '../../firebase-crud.service';
import { SignalsStoreService } from '../../signals-store.service';
import { DateTime } from 'luxon';
import { first, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '../../local-storage.service';
import { Session } from '../../types/session.type';

@Component({
  selector: 'app-dislike-confirmation',
  imports: [
    MatCheckbox
  ],
  templateUrl: './dislike-confirmation.component.html',
  styleUrl: './dislike-confirmation.component.scss'
})
export class DislikeConfirmationComponent {

  //#region Services

  #activeModal = inject(NgbActiveModal);

  #signalsStoreService = inject(SignalsStoreService);

  #firebaseCrudService = inject(FirebaseCrudService);

  #localStorageService = inject(LocalStorageService);

  //#endregion

  //#region Methods

  close(isConfirm = false) {
    this.#activeModal.close({ isConfirm });
  }

  async handleCheckboxChange(checked: boolean) {

    const session: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    const userId = session?.accountInfo.id;
    if (!userId) return;

    const preferences = this.#signalsStoreService.userPreferences();

    preferences.dontShowAgainPrevDislike = {
      value: checked,
      date: DateTime.utc().toMillis()
    };

    const exists = await this.#firebaseCrudService.existsDoc(FIREBASE_COLLECTIONS.PREFERENCES, userId.toString());
    if (exists)
      this.#firebaseCrudService.update(FIREBASE_COLLECTIONS.PREFERENCES, userId, preferences);
    else
      this.#firebaseCrudService.add(FIREBASE_COLLECTIONS.PREFERENCES, userId, preferences);
  }

  //#endregion
}
