import { CommonModule } from '@angular/common';
import { Component, Signal, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyFormatDirective } from '../../directives/currency-format.directive';
import { DeliveriesService } from '../../../settings/account/deliveries/deliveries.service';
import { DeliveryInformation } from '../../../settings/account/deliveries/intarfaces';
import { formatDateToReadableString } from '../../utils/formatting';

@Component({
    selector: 'app-check-submit-order',
    imports: [
        MatRadioModule,
        CommonModule,
        FormsModule,
        MatFormField,
        MatLabel,
        CurrencyFormatDirective,
    ],
    templateUrl: './check-submit-order.component.html',
    styleUrl: './check-submit-order.component.scss'
})
export class CheckSubmitOrderComponent {
  private deliveriesService = inject(DeliveriesService);
  private activeModal = inject(NgbActiveModal);

  deliveryInfo: Signal<any | null> = computed(() => this.setUpCutOffInfo());

  execute() {
    this.activeModal.close({
      submitOrder: true,
    });
  }

  private setUpCutOffInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null;

    const { cutoffDate, cutoffTime } =
      this.deliveriesService.deliveryZoneInfo() as DeliveryInformation;
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate);

    return {
      cutoffDate: cutoffDateFormatted,
      cutoffTime: cutoffTime,
    };
  }

  closeModal(canDeactivate: boolean = false) {
    this.activeModal.close({ canDeactivate });
  }
}
