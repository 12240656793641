import { inject, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ANetPayload } from "./authorize.types";
import { NotificationService } from "../../notification/notification.service";
import { LoadingService } from "../../loading.service";

declare global {
  interface Window {
    Accept: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {

  #notificationService = inject(NotificationService);
  #loadingService = inject(LoadingService);

  #APILoginID = environment.apis.authorize.APILoginID;
  #PulicClientKey = environment.apis.authorize.PublicClientkey;

  sendPaymentDataToAnet(cardData: ANetPayload, responseHandler: Function) {
    const authData = {
      clientKey: this.#PulicClientKey,
      apiLoginID: this.#APILoginID
    };

    const secureData = {
      authData,
      cardData
    };

    this.#loadingService.isShowGlobalLoading.set(true);
    window.Accept.dispatchData(secureData, (response: any) => {
      try {
        if (response.messages.resultCode === "Error") {
          this.#loadingService.close();
          var i = 0;
          while (i < response.messages.message.length) {
            console.log(
              response.messages.message[i].code + ": " +
              response.messages.message[i].text
            );
            this.#notificationService.show({ text: response.messages.message[i].text, type: 'warning' });
            i = i + 1;
          }
        } else {
          responseHandler(response.opaqueData);
        }
      } catch (error) {
        this.#loadingService.close();
        console.log('ANet Error >>', error);
      }
    });
  }

}
