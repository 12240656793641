import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-membership-csa-deposit',
    imports: [MatRadioModule, CommonModule, FormsModule],
    templateUrl: './membership-csa-deposit.component.html',
    styleUrl: './membership-csa-deposit.component.scss'
})
export class MembershipCsaDepositComponent {

  private activeModal = inject(NgbActiveModal);

  csaDeposits = [0, 100, 300, 600];
  csaDepositSelected = signal(this.csaDeposits[0]);

  closeModal(subscribe: boolean) {
    const deposit = subscribe ? {deposit: this.csaDepositSelected()} : null
    this.activeModal.close(deposit);
  }
}
