import { Item } from "../types/common.types";

export interface SidebarCheckList extends ItemCheck {
  id: number | string;
  sublist?: SidebarCheckList[];
}

export interface Sidebar {
  categories?: ItemCheck[];
  subcategories?: ItemCheck[];
  subcategoriesSons?: ItemCheck[];
  producers?: ItemCheck[];
  dietRestrictions?: ItemCheck[];
}

export interface ItemCheck extends Item {
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  key?: string;
  sons?: Item[];
  subcategories?: Item[];
}

export enum SidebarFilterTypes {
  categories = 'categories',
  subcategories = 'subcategories',
  producers = 'producers',
  dietRestrictions = 'dietrestrictions',
  tags = 'tags'
};

export enum SidebarFilterActions {
  isFiltering = 'isFiltering',
  isClearing = 'isClearing'
}