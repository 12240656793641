import { Component, inject } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { RouterLink, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { SignUpSteps } from '../../types/common.enums';

@Component({
    selector: 'app-pending-payment-method-no-signup',
    providers: [provideNativeDateAdapter()],
    imports: [RouterLink],
    template: `
  <div class="container mt-3">
    <div class="row">
      <div class="col mb-3 text-center">
        <span class="h5-regular">
        Welcome back! We noticed that you don't have a payment method registered on your account. To continue shopping and enjoy a seamless checkout experience, please add a payment method now.
        </span>
      </div>
    </div>

    <div class="row mt-0 mb-2 mt-3 justify-content-center">
      <div class="col-md-auto">
        <button
          type="button"
          class="btn btn-primary"
          (click)="activeModal.close()"
          [routerLink]="'settings/billing/payment-method'">
          Add Payment Method
        </button>
      </div>
    </div>
  </div>
`
})
export class PendingPaymentMethodNoSignupComponent {
  activeModal = inject(NgbActiveModal);
}
