import { Component, Signal, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-delete-tip-donation',
    imports: [MatCheckboxModule, FormsModule, MatFormField],
    templateUrl: './delete-tip-donation.component.html',
    styleUrl: './delete-tip-donation.component.scss'
})
export class DeleteTipDonationComponent {
  private activeModal = inject(NgbActiveModal);

  applyToAllOrders: Signal<boolean> = signal(false);

  confirmDelete() {
    this.activeModal.close({
      applyToAllOrders: this.applyToAllOrders(),
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
