<div class="d-flex flex-column">

  <p class="h5-regular mt-2">
    Welcome to our new e-commerce experience! As a part of this new experience, we will be automatically subbing your
    dislikes out of your Farmboxes creating a personalized experience just for you. Confirm your dislikes here:
  </p>

  <!-- Dislikes -->
  @defer (when dislikes().length) {
  <section>
    <h1 class="fw-bolder animated fadeInLeft h3-bold">
      Dislikes
    </h1>

    <div class="row">
      @for (product of dislikes(); track 'dislike__'+product.variantId) {
      <div class="col-xxl-3 col-lg-4 col-12 animated fadeIn">
        <div class="product-card-grid__item">
          <app-product-card-v2 [cardResume]="{ settings: {}, productDetails: product }" [isPreference]="true"
            (outClickFavoriteOrDislike)="changeDislikeState($event)"></app-product-card-v2>
        </div>
      </div>
      } @empty {
      <app-empty-message [message]="'There are no products marked as disliked.'"></app-empty-message>
      }
    </div>
  </section>
  }

  <!-- Likes -->
  @defer (when likes().length) {
  <section>
    <h1 class="fw-bolder animated fadeInLeft h3-bold">
      Favorites
    </h1>

    <div class="row">
      @for (product of likes(); track 'like_'+product.variantId) {
      <div class="col-xxl-3 col-lg-4 col-12 animated fadeIn">
        <div class="product-card-grid__item">
          <app-product-card-v2 [cardResume]="{ settings: {}, productDetails: product }" [isPreference]="true"
            (outClickFavoriteOrDislike)="changeFavoriteState($event)"></app-product-card-v2>
        </div>
      </div>
      } @empty {
      <app-empty-message [message]="'There are no products marked as favorites.'"></app-empty-message>
      }
    </div>
  </section>
  }

  <button type="button" class="btn btn-primary m-auto px-3 w-25 mt-4" [class.w-50]="isMobile()" (click)="close()">
    Confirm
  </button>

</div>