import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { DATA_LAYER_EVENT, DATA_LAYER_LOGIN_STATE_OPTIONS, DataLayer_CheckAddress, DataLayer_Event, DataLayer_FormSubmission, DataLayer_LoginState, DataLayer_User, DATA_LAYER_FORM_SUBMISSION_ID_OPTIONS, DataLayer_Subscribe, DataLayer_ViewItemList, DataLayer_ViewItem, DataLayer_AddToCart, DataLayer_BeginCheckout } from './data-layer.types';
import { LocalStorageService } from '../local-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { Session } from '../types/session.type';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  #localStorageService = inject(LocalStorageService);

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  trackLoginEvent() {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_User>({
      event: DATA_LAYER_EVENT.login,
      user
    });
  }

  trackSignUpEvent() {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_User>({
      event: DATA_LAYER_EVENT.sign_up,
      user
    });
  }

  trackLoginStatusEvent() {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_LoginState>({
      event: DATA_LAYER_EVENT.login_state,
      data: {
        status: DATA_LAYER_LOGIN_STATE_OPTIONS.logged_in,
      },
      user
    });
  }

  trackCheckAddressEvent(delivery_area: boolean) {
    this.#trackEvent<DataLayer_CheckAddress>({
      event: DATA_LAYER_EVENT.check_address,
      data: {
        delivery_area
      }
    })
  }

  trackDeliveryAreaFormSubmissionEvent(user: DataLayer_User) {
    this.#trackEvent<DataLayer_FormSubmission>({
      event: DATA_LAYER_EVENT.form_submission,
      data: {
        form_id: DATA_LAYER_FORM_SUBMISSION_ID_OPTIONS.delivery_area_update,
      },
      user
    })
  }

  trackNewsletterFormSubmissionEvent(email: string) {
    this.#trackEvent<DataLayer_FormSubmission>({
      event: DATA_LAYER_EVENT.form_submission,
      data: {
        form_id: DATA_LAYER_FORM_SUBMISSION_ID_OPTIONS.newsletter,
      },
      user: { email }
    })
  }

  trackSubscribeEvent(data: DataLayer_Subscribe) {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_Subscribe>({
      event: DATA_LAYER_EVENT.subscribe,
      data,
      user
    })
  }

  trackViewItemListEvent(data: DataLayer_ViewItemList) {
    const user: DataLayer_User | undefined = this.#isTrackEventValid() || undefined;
    this.#trackEvent<DataLayer_ViewItemList>({
      event: DATA_LAYER_EVENT.view_item_list,
      data,
      user
    })
  }

  trackViewItemEvent(data: DataLayer_ViewItem) {
    const user: DataLayer_User | undefined = this.#isTrackEventValid() || undefined;
    this.#trackEvent<DataLayer_ViewItem>({
      event: DATA_LAYER_EVENT.view_item,
      data,
      user
    })
  }

  trackAddToCartEvent(data: DataLayer_AddToCart) {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_AddToCart>({
      event: DATA_LAYER_EVENT.add_to_cart,
      data,
      user
    })
  }

  trackBeginCheckoutEvent(data: DataLayer_BeginCheckout) {
    const user: DataLayer_User | null = this.#isTrackEventValid();
    if (!user) return;
    this.#trackEvent<DataLayer_BeginCheckout>({
      event: DATA_LAYER_EVENT.begin_checkout,
      data,
      user
    })
  }

  #trackEvent<T>(info: DataLayer_Event<T>) {
    try {
      if (!this.#isPlatformBrowser() || !environment.production) return;
      const payload: any = {
        event: info.event,
        ...info.data,
      }

      if (info.user)
        payload.user = info.user;

      window.dataLayer.push(payload);
    } catch (error) {
      console.log('Error Tracking DataLayer Event: ', info);
    }
  }

  #hasSession() {
    const currentSession: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    return !!(currentSession?.accountInfo?.id)
  }

  #isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  #getUserInfo(): DataLayer_User | null {
    const session: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!session || !session.accountInfo) return null;
    return {
      email: session.accountInfo.email,
      first_name: session.accountInfo.firstName,
      last_name: session.accountInfo.lastName,
      phone: session.accountInfo.phone,
      user_id: session.accountInfo.id.toString()
    } as DataLayer_User
  }

  #isTrackEventValid(): DataLayer_User | null {
    if (!this.#hasSession()) return null;
    return this.#getUserInfo();
  }

}