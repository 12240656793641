import { Component, inject, signal } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-hold-up-subscriptions',
    imports: [MatCheckboxModule],
    templateUrl: './hold-up-subscriptions.component.html',
    styleUrl: './hold-up-subscriptions.component.scss'
})
export class HoldUpSubscriptionsComponent {

  private activeModal = inject(NgbActiveModal);

  avoidHoldUpMessage = signal(false);

  handleCheckboxChange(checked: boolean) {
    this.avoidHoldUpMessage.set(checked);
  }

  confirmModalButtonsAction(confirmChanges: boolean) {
    this.activeModal.close({ confirmChanges, avoid: this.avoidHoldUpMessage() });
  }
}
