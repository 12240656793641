import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignalsStoreService } from '../../signals-store.service';
import { formatCurrency, formatCurrencyToAmount } from '../../utils/formatting';
import { CurrencyFormatDirective } from '../../directives/currency-format.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrderService } from '../../order.service';
import { ResolutionService } from '../../resolution.service';

@Component({
    selector: 'app-donation',
    imports: [
        MatRadioModule,
        CommonModule,
        FormsModule,
        MatFormField,
        MatLabel,
        CurrencyFormatDirective,
        MatCheckboxModule,
    ],
    templateUrl: './donation.component.html',
    styleUrl: './donation.component.scss'
})
export class DonationComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private signalsStoreService = inject(SignalsStoreService);
  private orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);
  recurrentAmount = formatCurrency(0.0);
  oneTimeAmount = formatCurrency(0.0);
  selectedOption: WritableSignal<string> = signal('');
  showApplyToAll: WritableSignal<boolean> = signal(false);
  applyToAllOrders: WritableSignal<boolean> = signal(false);
  isMobile = computed(() => this.resolutionService.isMobile());

  ngOnInit() {
    const donation = this.orderService.odooOrder()?.paymentDetails.donation;
    if (donation?.amount) {
      this.showApplyToAll.set(true)
      this.selectedOption.set(donation?.isRecurrent ? 'RECURRENT' : 'ONE_TIME');
      if (donation?.isRecurrent) {
        this.recurrentAmount = formatCurrency(
          donation?.amount ||
            this.signalsStoreService.odooDonationAmountVoluntary()
        );
      } else {
        this.oneTimeAmount = formatCurrency(donation?.amount);
      }
    }
  }

  donate() {
    const isRecurrent = this.selectedOption() === 'RECURRENT';
    const amount = formatCurrencyToAmount(
      isRecurrent ? this.recurrentAmount : this.oneTimeAmount
    );

    this.activeModal.close({
      isRecurrent,
      amount,
      applyToAllOrders: this.applyToAllOrders()
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
