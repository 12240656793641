import { Component, computed, inject, signal, OnInit, input } from '@angular/core';
import { ResolutionService } from '../../resolution.service';
import { FavOrDislike } from '../../product-card/product-card.types';
import { Product } from '../../../product/product.types';
import { PreferencesDislikeFav } from '../../types/common.types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { ProductCardV2Component } from '../../product-card-v2/product-card-v2.component';

@Component({
  selector: 'app-dislike-fav',
  imports: [
    ProductCardV2Component,
    EmptyMessageComponent
  ],
  templateUrl: './dislike-fav.component.html',
  styleUrl: './dislike-fav.component.scss'
})
export class DislikeFavComponent implements OnInit {

  //#region Services

  #resolutionService = inject(ResolutionService);

  #activeModal = inject(NgbActiveModal);

  //#endregion

  //#region Properties

  isMobile = computed(() => this.#resolutionService.isMobile());

  preferences = input.required<PreferencesDislikeFav>();

  likes = signal<Product[]>([]);

  dislikes = signal<Product[]>([]);

  //#endregion

  //#region Constructor

  constructor() { }

  ngOnInit() {
    this.likes.set(this.preferences().likes);
    this.dislikes.set(this.preferences().dislikes);
  }

  //#endregion

  //#region Methods

  changeFavoriteState(event: FavOrDislike) {

    if (!event.isDisliked === event.value)
      return;

    this.likes.update(value => {

      const index = value.findIndex(x => x.id === event.id);

      if (index !== -1) {

        const product = value.splice(index, 1);

        if (event.isDisliked && event.value)
          this.dislikes.update(dislikes => {
            product[0].fav = false;
            product[0].disliked = true;
            dislikes.push(product[0]);
            return [...dislikes];
          });
      }

      return [...value];
    });
  }

  changeDislikeState(event: FavOrDislike) {

    if (event.isDisliked === event.value)
      return;

    this.dislikes.update(value => {

      const index = value.findIndex(x => x.id === event.id);

      if (index !== -1) {

        const product = value.splice(index, 1);

        if (!event.isDisliked && event.value)
          this.likes.update(dislikes => {
            product[0].fav = true;
            product[0].disliked = false;
            dislikes.push(product[0]);
            return [...dislikes];
          });
      }

      return [...value];
    });
  }

  close() {
    this.#activeModal.close();
  }

  //#endregion
}
