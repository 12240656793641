import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-suspended-account',
    imports: [],
    templateUrl: './suspended-account.component.html',
    styleUrl: './suspended-account.component.scss'
})
export class SuspendedAccountComponent {
  activeModal = inject(NgbActiveModal);
  router = inject(Router);

  goToPaymentMethod() {
    this.router.navigate(['/settings/billing/payment-method']);
    this.activeModal.close();
  }
}
