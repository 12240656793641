@if (!isMobile()) {
  <div class="row mt-4">
    <div class="col table-responsive table-container overflow-y-auto"> 
        <table class="table align-middle table-striped table-sm">
          <thead class="sticky-top">
            <tr>
              <th class="border-0 align-middle text-body-tertiary" scope="col">Product name</th>
              @if (!products.fromOrder) {
                <th class="border-0 align-middle text-body-tertiary" scope="col">Product variant</th>
              }
              @if (products.showThirdColumn) {
                <th class="border-0 align-middle text-body-tertiary" scope="col"></th>
              }
            </tr>
          </thead>
          <tbody class="">
            @for (product of products.products; track $index) {
                <tr [class.noStock]="product.outOfStock && !products.fromOrder">
                    <td>
                        <b>{{product.productName}} {{products.fromOrder && product.productVariant ? (' | ' + product.productVariant) : ''}}</b>
                    </td>
                    @if (!products.fromOrder) {
                      <td>
                          <span>{{product.productVariant}}</span>
                      </td>
                    }
                    @if (products.showThirdColumn) {
                      @if (product.reason) {
                        <td>{{product.reason}}</td>
                      } @else {
                        <td>{{product.outOfStock ? (products.fromOrder ? 'Removed due to insufficient stock' : 'Out of stock') : product.limitExceeded ? (products.fromOrder ? 'Reduced to qty. '+ product.previousQuantity +' due to limited stock' : 'Limit exceeded') : 'In your order'}}</td>
                      }
                    }
                </tr>
            }
          </tbody>
        </table>
    </div>
  </div>
} @else {
  <div class="mt-2 vstack gap-2 animated fadeIn">
    @for (product of products.products; track $index) {
      <section 
        class="row align-items-center" 
        [class.bg-body-tertiary]="product.outOfStock"
        >
        <section class="col-7">
          <section class="vstack">
            <b [class.text-body-tertiary]="product.outOfStock">{{product.productName}}</b>
            @if (product.productVariant !== '---') {
              <small [class.text-body-tertiary]="product.outOfStock">{{product.productVariant}}</small>
            }
          </section>
        </section>
        <section class="col-5">
            <section class="ms-auto" [class.text-body-tertiary]="product.outOfStock">
              <span class="float-end">{{product.outOfStock ? (products.fromOrder ? 'Removed due to insufficient stock' : 'Out of stock') : product.limitExceeded ? (products.fromOrder ? 'Reduced to '+ product.previousQuantity +' due to limited stock' : 'Limit exceeded') : 'In your order'}}</span>
            </section>
        </section>
      </section>
      <hr class="m-0">
    }
  </div>
}

<div class="d-grid gap-2 col-6 mx-auto mt-4">
  <button 
    type="button"
    (click)="closeModal()"
    class="btn btn-primary d-flex gap-2 align-items-center justify-content-center">
    I understand
  </button>
</div>