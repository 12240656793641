import { Component, Input, computed, inject } from '@angular/core';
import { OrderHistoryWarning } from '../modal-content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResolutionService } from '../../resolution.service';

@Component({
    selector: 'app-buy-again-order-history-warning',
    imports: [],
    templateUrl: './buy-again-order-history-warning.component.html',
    styleUrl: './buy-again-order-history-warning.component.scss'
})
export class BuyAgainOrderHistoryWarningComponent {

  private activeModal = inject(NgbActiveModal);
  private resolutionService = inject(ResolutionService);

  isMobile = computed(() => this.resolutionService.isMobile());

  @Input() products: { products: OrderHistoryWarning[], showThirdColumn: boolean, fromOrder: boolean } = { products: [], showThirdColumn: true, fromOrder: true };

  closeModal() {
    this.activeModal.close();
  }
}
