import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { BehaviorSubject, catchError, finalize, map, of, take, tap } from 'rxjs';
import { ModalContentService } from './modal-content/modal-content.service';
import { ModalContentData } from './modal-content/modal-content';
import { ModalContentTypes } from './constants/modal-content-types';
import { SignalsStoreService } from './signals-store.service';
import { LocalStorageService } from './local-storage.service';
import { LOCALSTORAGE_KEYS } from './constants/databases';
import { DataLayerService } from './data-layer/data-layer.service';

export interface NavegoAddressResponse {
  city: string;
  clientNr: string;
  cutoffDay: string;
  cutoffTime: string;
  deliveryDay: string;
  deliveryWindow: string;
  latitude: number;
  longitude: number;
  state: string;
  street: string;
  zoneId: number;
  zoneName: string;
}
export interface NavegoAddressRequest {
  street: string;
  city: string;
  state: string;
  latitude: number;
  longitude: number
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private modalContentService = inject(ModalContentService);
  private signalsStoreService = inject(SignalsStoreService);
  private localStorageService = inject(LocalStorageService);
  #dataLayerService = inject(DataLayerService);

  private setFirstPickUpMembershipSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  setFirstPickUpMembershipSelected$ = this.setFirstPickUpMembershipSelectedSubject.asObservable();


  endpoints: any = {
    login: `${env.apis.v1}/login`
  }
  isLoading: WritableSignal<boolean> = signal(false);
  navegoAddress: WritableSignal<NavegoAddressResponse | undefined> = signal(undefined);

  constructor(private http: HttpClient) { }

  checkDeliveryAddress(addressInfo: NavegoAddressRequest, openModalProspect: boolean = true, anonymousUser: boolean = false) {
    this.navegoAddress.set(undefined)
    const apiUrl = `${env.apis.navego.url}/zones/checkaddress`;
    const bodyRequest = {
      ...addressInfo,
      clientNr: env.apis.navego.clientNr,
      apiKey: env.apis.navego.key
    }
    this.isLoading.set(true);

    return this.http.post<NavegoAddressResponse>(`${apiUrl}`, bodyRequest).pipe(
      take(1),
      finalize(() => {
        this.isLoading.set(false);
      })
    ).pipe(
      tap((response: any) => {

        this.navegoAddress.set(response?.mybody || null)
        if (anonymousUser) {
          this.setUpAnonymousUserAddress(response.mybody);
        }
        this.#dataLayerService.trackCheckAddressEvent(true);
      }),
      catchError(error => {
        console.log(error);
        if (anonymousUser)
          this.localStorageService.remove(LOCALSTORAGE_KEYS.ANONYMOUS_ADDRESS);

        if (openModalProspect)
          this.openModalProspect();

        this.#dataLayerService.trackCheckAddressEvent(false);
        return of(null)
      })
    )
  }

  setupDeliveryData(place: google.maps.places.PlaceResult) {
    let city = '';
    let stateCode = '';
    let zipCode = '';
    let latitude: number | (() => number) | undefined = place.geometry?.location?.lat
    let longitude: number | (() => number) | undefined = place?.geometry?.location?.lng
    if (typeof latitude == 'function') latitude = latitude();
    if (typeof longitude == 'function') longitude = longitude();
    let streetNameArray = ['', ''];

    let locality, vicinity, neighborhood;

    vicinity = place?.vicinity;

    for (const element of place.address_components || []) {
      for (let j = 0; j < element.types.length; j++) {

        if (element.types[j] === 'locality') {
          locality = element.long_name;
          continue;
        }

        if (element.types[j] === 'neighborhood' && !city) {
          neighborhood = element.long_name;
          continue;
        }

        if (element.types[j] === 'administrative_area_level_1') {
          stateCode = element.short_name;
          continue;
        }

        if (element.types[j] === 'postal_code') {
          zipCode = element.long_name;
          continue;
        }

        if (element.types[j] === 'street_number') {
          streetNameArray[0] = element.short_name;
          continue;
        }

        if (element.types[j] === 'route') {
          streetNameArray[1] = element.short_name;
          continue;
        }

      }
    }

    city = locality || vicinity || neighborhood || '-';

    let formattedAddress = place.formatted_address;
    const name = streetNameArray.join(' ').trim() || place.name;

    return {
      city,
      stateCode,
      zipCode,
      latitude,
      longitude,
      formattedAddress,
      name,
      placeId: place.place_id
    }
  }

  private openModalProspect() {
    const modalRef = this.modalContentService.openModal(
      ModalContentTypes.PROSPECT,
      {
        title: 'Oops!'
      }
    );
    modalRef.closed.subscribe((result) => console.log(result))
  }

  private setUpAnonymousUserAddress(res: any) {
    const addressInfo = {
      locationText: this.signalsStoreService.googleAddress().street,
      ...this.signalsStoreService.googleAddress()
    }
    this.localStorageService.set(LOCALSTORAGE_KEYS.ANONYMOUS_ADDRESS, { ...addressInfo });
    this.signalsStoreService.anonymousUserAddress.set({ ...addressInfo });
  }

  setFirstPickUpMembershipSelected() {
    this.setFirstPickUpMembershipSelectedSubject.next(true);
  }

}
