import { environment as env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, take, tap } from 'rxjs';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { Location } from '@angular/common';
import { LOCALSTORAGE_KEYS } from './constants/databases';
import { Session } from './types/session.type';
import { RequestHandlerParams } from './types/api-service.types';
import { AccountSettings, DeliveryOptionsResponseItem, NewAccountPayload, normalizeECommercePermissionsResponse, PaymentDataPayload, PaymentMethodResponse } from './types/account.types';
import { ApiResponse } from './common/types';
import { MarketingReferral } from './types/common.types';
import { SignalsStoreService } from './signals-store.service';
import { NotificationService } from './notification/notification.service';
import { HTTP_STATUS_CODES } from './constants/errors';
import { DataLayerService } from './data-layer/data-layer.service';
// import { OrderService } from './order.service';

export interface PaymentMethod {
  address?: string;
  billingAddressCheck?: boolean;
  additionalAddress?: string;
  cardCode: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  city?: string;
  email: string;
  expirationDate: string;
  state?: string;
  zipCode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private apiService = inject(ApiService)
  private localStorageService = inject(LocalStorageService)
  private location = inject(Location)
  private signalsStoreService = inject(SignalsStoreService);
  #notificationService = inject(NotificationService);
  #dataLayerService = inject(DataLayerService);

  endpoints = {
    paymentMethod: `/payment-method`,
    prospect: `${env.apis.v3}/prospect`,
    base: `/account`,
    deliveryOptions: '/delivery-options',
    pickUpOption: `/pickup-locations`,
    settings: '/settings',
    customerTypes: `/customer-types`,
    stockNotification: '/stock-notification',
    ecommercePermissions: '/ecommerce-permissions'
  }
  isLoading: WritableSignal<boolean> = signal(false);

  private mustFetchStatesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mustFetchStates$ = this.mustFetchStatesSubject.asObservable();

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  create(data: NewAccountPayload) {
    this.isLoading.set(true);
    let body = data;
    const marketingReferralData = this.localStorageService.get(LOCALSTORAGE_KEYS.MARKETING_REFERRAL) as MarketingReferral;
    if (marketingReferralData && Object.keys(marketingReferralData).length) {
      body = {
        ...body,
        marketingReferralData
      };
    }
    const params: RequestHandlerParams = {
      endpoint: this.endpoints.base,
      method: 'POST',
      apiV3: true,
      body,
      returnError: true,
      showErrorMessage: false
    };

    return this.apiService.handleRequest<ApiResponse<Session>>(params).pipe(
      tap(response => {
        if (response.error && response.status !== HTTP_STATUS_CODES.CONFLICT)
          this.#notificationService.show({ text: response.message, type: 'error' });
      }),
      tap(response => {
        if (!!response.data && !!!response.data.isTimeSlotCapacityFull) {
          this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, { ...response.data });
          this.signalsStoreService.hasSession.set(true);
          this.getMembershipPermissions().subscribe();
        }
      }),
      finalize(() => this.isLoading.set(false))
    );
  }

  addPaymentMethod(body: PaymentDataPayload, newUser: boolean = true) {
    const params: RequestHandlerParams = {
      method: 'POST',
      endpoint: `${this.endpoints.base}${this.endpoints.paymentMethod}`,
      body,
      showErrorMessage: true,
      apiV3: true
    }

    this.isLoading.set(true);

    return this.apiService.handleRequest<ApiResponse<PaymentMethodResponse>>(params)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        tap((response) => {
          if (response?.data) {
            const { settings, card: creditCard } = response.data;
            const sessionStored: Session = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) as any
            const newSessionStored: Session = { ...sessionStored, settings, creditCard }
            this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSessionStored);
            this.localStorageService.set(LOCALSTORAGE_KEYS.FIRST_LOGIN, !!settings.firstLogin);
            if (newUser) {
              this.localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
              this.location.replaceState('/shop');
              window.location.reload();
            }
          }
        })
      )
  }

  addProspect(clientInfo: {
    firstName: string;
    lastName: string;
    email: string;
    latitude: string;
    longitude: string;
    address: string;
    city: string;
    stateCode: string;
    zipCode: string;
  }) {
    const apiUrl = this.endpoints.prospect;
    const bodyRequest = {
      ...clientInfo
    }
    this.isLoading.set(true);

    return this.http.post<any>(apiUrl, bodyRequest).pipe(
      catchError((error) => this.errorHandlerService.handleHttpError(error)),
      take(1),
      finalize(() => {
        console.log('finalized');
        this.isLoading.set(false);
      })
    )

  }

  getDeliveryOptions(zoneId?: number) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.base}${this.endpoints.deliveryOptions}${zoneId ? `?zoneId=${zoneId}` : ''}`,
      method: 'GET',
      apiV3: true
    }
    return this.apiService.handleRequest<ApiResponse<DeliveryOptionsResponseItem[]>>(params).pipe(
      map((response: ApiResponse<DeliveryOptionsResponseItem[]>) => this.setUpDeliveryOptionsResponse(response.data))
    );
  }

  private setUpDeliveryOptionsResponse(data: DeliveryOptionsResponseItem[]): DeliveryOptionsResponseItem[] {
    // Mapping of days of the week to numeric values
    const weekOrder: any = {
      'monday': 0,
      'tuesday': 1,
      'wednesday': 2,
      'thursday': 3,
      'friday': 4,
      'saturday': 5,
      'sunday': 6
    };

    // Comparison function to sort the objects by name property
    const compareDays = (a: any, b: any) => {
      // Convert the name to lowercase for comparison
      const dayA = a.name.toLowerCase();
      const dayB = b.name.toLowerCase();
      return weekOrder[dayA] - weekOrder[dayB];
    };

    return data.sort(compareDays);
  }

  getAllCustomerTypes() {
    const params: RequestHandlerParams = {
      endpoint: this.endpoints.customerTypes,
      method: 'GET',
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      finalize(() => this.isLoading.set(false))
    );
  }

  getPickUpOption(membershipId: number) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.base}${this.endpoints.pickUpOption}?membershipId=${membershipId}`,
      method: 'GET',
      apiV3: true,
      showErrorMessage: false
    }

    return this.apiService.handleRequest<any[]>(params).pipe(
      map((data) => this.setUpPickupOptionsResponse(data))
    );
  }

  private setUpPickupOptionsResponse(data: any[]): any {
    // Mapping of days of the week to numeric values
    const weekOrder: any = {
      'monday': 0,
      'tuesday': 1,
      'wednesday': 2,
      'thursday': 3,
      'friday': 4,
      'saturday': 5,
      'sunday': 6
    };

    // Comparison function to sort the objects by name property
    const compareDays = (a: any, b: any) => {
      // Convert the name to lowercase for comparison
      const dayA = a.name.toLowerCase();
      const dayB = b.name.toLowerCase();
      return weekOrder[dayA] - weekOrder[dayB];
    };

    return data.map(p => {
      p.deliveryDays = p.deliveryDays.sort(compareDays);
      return p;
    });
  }

  getAccountSettings() {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.base}${this.endpoints.settings}`,
      method: 'GET',
      apiV3: true
    }
    return this.apiService.handleRequest<ApiResponse<AccountSettings>>(params).pipe(
      map((response: ApiResponse<AccountSettings>) => response.data)
    );
  }

  notifyOOS(body: { email?: string, phone?: string } | null, productId: number) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.base}${this.endpoints.stockNotification}/${productId}`,
      method: 'POST',
      apiV3: true,
      body
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params);
  }

  getMembershipPermissions(routeToNavigate?: string, comesFromLogin: boolean = false) {
    const params: RequestHandlerParams = {
      endpoint: this.endpoints.ecommercePermissions,
      method: 'GET',
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response) => {
        // Get membersip configurations for update localstorage session and configuration signals:
        const isLimited = response?.data?.isLimited ?? false;
        this.signalsStoreService.isLimitedUser.set(isLimited);
        const config = response?.data?.configuration;
        if (config) {
          const currentSession: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
          if (currentSession?.settings) {

            const requireAddress = config.requireAddress ?? true;
            this.signalsStoreService.isAddressRequired.set(requireAddress);
            const requirePaymentMethod = config.requirePaymentMethod ?? true;

            const currentSettings = currentSession.settings;
            const newSettings = { ...currentSettings, isLimited, requireAddress, requirePaymentMethod }

            const newSession = { ...currentSession, settings: { ...newSettings } };

            this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSession);

            if (comesFromLogin)
              this.#dataLayerService.trackLoginEvent();

          }
        }
        if (routeToNavigate)
          this.reloadPage(routeToNavigate);
      }),
      map((response) => normalizeECommercePermissionsResponse(response.data?.permissions)),
      tap((permissions) => {
        this.signalsStoreService.permissions.set(permissions);
        this.localStorageService.set(LOCALSTORAGE_KEYS.PERMISSIONS, permissions)
      })
    );
  }

  private reloadPage(route: string) {
    this.location.replaceState(route);
    window.location.reload();
  }

  setMustFetchStates() {
    this.mustFetchStatesSubject.next(true);
  }


}
