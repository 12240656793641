<div class="container">
  <div class="row">
    <div class="col mb-3">
      <h5 class="pb-4 text-center">Unfortunately we don’t deliver to your area yet...</h5>
      <small class="text-center d-block">
        Leave your information and we will let you know when we deliver to your
        area. Please email <b>{{applicationEmail}}</b> if you think this is a mistake or you
        are close to our delivery area
      </small>
    </div>
  </div>

  <form class="needs-validation">
    <div class="row mt-4 pb-2 row-gap-3">
      <div [class.col-lg-6]="!computedZipCode()" [class.col-lg-3]="computedZipCode()" class="col-md-12">
        <div class="form-floating has-validation">
          <input type="text" class="form-control" placeholder name="firstName"
            [class.is-invalid]="!prospectInformationValidations.get('firstName')?.()?.valid"
            [ngModel]="prospectForm()?.firstName"
            (ngModelChange)="updateProspectForm({firstName: $event }, 'firstName')">
          <label for="firstName">First Name</label>
          @if (!prospectInformationValidations.get('firstName')?.()?.valid) {
          <div class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ prospectInformationValidations.get('firstName')?.()?.error }}
          </div>
          }
        </div>
      </div>
      <div [class.col-lg-6]="!computedZipCode()" [class.col-lg-3]="computedZipCode()" class="col-md-12">
        <div class="form-floating has-validation">
          <input type="text" class="form-control" placeholder name="lastName"
            [class.is-invalid]="!prospectInformationValidations.get('lastName')?.()?.valid"
            [ngModel]="prospectForm()?.lastName" (ngModelChange)="updateProspectForm({lastName: $event }, 'lastName')">
          <label for="lastName">Last Name</label>
          @if (!prospectInformationValidations.get('lastName')?.()?.valid) {
          <div class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ prospectInformationValidations.get('lastName')?.()?.error }}
          </div>
          }
        </div>
      </div>
      <div [class.col-lg-8]="!computedZipCode()" [class.col-lg-6]="computedZipCode()" class="col-md-12">
        <div class="form-floating has-validation">
          <input type="email" class="form-control" placeholder name="email"
            [class.is-invalid]="!prospectInformationValidations.get('email')?.()?.valid"
            [ngModel]="prospectForm()?.email" (ngModelChange)="updateProspectForm({email: $event }, 'email')">
          <label for="email">Email</label>
          @if (!prospectInformationValidations.get('email')?.()?.valid) {
          <div class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ prospectInformationValidations.get('email')?.()?.error }}
          </div>
          }
        </div>
      </div>
      @if(!computedZipCode()){
      <div class="col-lg-4 col-md-12">
        <div class="form-floating has-validation">
          <input type="text" maxlength="5" class="form-control" placeholder name="zipCode" appOnlyNumber
            [class.is-invalid]="!prospectInformationValidations.get('zipCode')?.()?.valid"
            [ngModel]="prospectForm()?.zipCode" (ngModelChange)="updateProspectForm({zipCode: $event }, 'zipCode')">
          <label for="zipCode">Zip code</label>
          @if (!prospectInformationValidations.get('zipCode')?.()?.valid) {
          <div class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ prospectInformationValidations.get('zipCode')?.()?.error }}
          </div>
          }
        </div>
      </div>
      }
    </div>
  </form>

  <div class="row justify-content-md-center my-4 row-gap-3">
    <div class="col-lg-4 col-md-12">
      <button type="button" class="btn btn-primary w-100" [disabled]="!isValidFormProspectInformation()"
        (click)="addProspect()">
        Share my information
      </button>
    </div>
    <div class="col-lg-4 col-md-12">
      <button type="button" class="btn btn-outline-primary w-100" (click)="activeModal.close(false)">
        Try another address
      </button>
    </div>
  </div>
  <!-- <div class="col-12 d-flex justify-content-center">
      <button
          class="border-0 bg-transparent link-secondary text-decoration-underline"
          (click)="selectPickupOption()">
          Check pickup locations
      </button>
    </div> -->
</div>