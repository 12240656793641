import { Component, computed, inject, input, InputSignal, output } from '@angular/core';
import { ProductCardService } from '../../../product-card.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { OnlyNumberDirective } from '../../../directives/only-number.directive';

@Component({
    selector: 'app-bundle-substitution-product-card',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        NgbTooltip
    ],
    providers: [ProductCardService],
    templateUrl: './bundle-substitution-product-card.component.html',
    styleUrl: './bundle-substitution-product-card.component.scss'
})
export class BundleSubstitutionProductCardComponent {
  productCardService = inject(ProductCardService);
  private router = inject(Router);


  product: any = input(null, {
    transform: (product: any) => {
      product.quantity = product?.quantity || 1

      if (product?.quantity >= 10)
        this.productCardService.wasValueHigher10.set(true);

      this.productCardService.newQuantity.set(product?.quantity);
      return product;
    }
  });
  isLimitedUser: InputSignal<boolean> = input(false);
  isLockedBundle: InputSignal<boolean> = input(false);
  productKey: any = input(null)
  addItem = output<any>();
  removable: InputSignal<boolean> = input(false);
  changeQuantity = output<any>();

  onSelectItem = output<any>();

  newQuantity = computed(() => {
    const quantity = this.productCardService.newQuantity();
    if (quantity) {
      return this.productCardService.newQuantity()
    }
    return 1
  });

  // handleKeyUp(event: any) {
  //   this.productCardService.onKeyUp(event.value);
  //   if (this.removable())
  //     this.changeQuantity.emit({ ...this.product(), validationQuantity: this.productCardService.newQuantity() - this.product().quantity, quantity: this.productCardService.newQuantity() })
  // }

  // handleChangeQuantitySelect(value: any) {
  //   this.productCardService.onChangeQuantitySelect(value);
  //   if (this.removable())
  //     this.changeQuantity.emit({ ...this.product(), validationQuantity: this.productCardService.newQuantity() - this.product().quantity, quantity: this.productCardService.newQuantity() })
  // }

  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/product-placeholder.webp';
  }

  goToProduct() {
    if (!this.isLimitedUser())
      this.router.navigate(['/shop/' + this.product()?.productURL]);
  }


}
