import { afterNextRender, Component, computed, inject, input, Signal, signal, WritableSignal } from '@angular/core';
import { BoxDetail, BoxDetailItem } from '../modal-content';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDateToReadableString } from '../../utils/formatting';
import { Item } from '../../types/common.types';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ProductsService } from '../../../product/products.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { BundleItems } from '../../../product/product.types';
import { handleImageError } from '../../common/utils';
import { ResolutionService } from '../../resolution.service';
import { PublishedBundles } from './modal-box-detail.types';

@Component({
    selector: 'app-modal-box-detail',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule
    ],
    templateUrl: './modal-box-detail.component.html',
    styleUrl: './modal-box-detail.component.scss'
})
export class ModalBoxDetailComponent {

  #activeModal = inject(NgbActiveModal);
  #productsService = inject(ProductsService);
  #resolutionService = inject(ResolutionService);

  isMobile = computed(() => this.#resolutionService.isMobile());
  isSmallMobile = computed(() => this.#resolutionService.isSmallMobile());
  isDesktop = computed(() => !this.isMobile() && !this.isSmallMobile());

  publishedBundles: Signal<PublishedBundles[]> = computed(() => this.#setUpPublishedBundles())
  #publishedBundles$ = toObservable(this.publishedBundles);
  items: WritableSignal<BoxDetailItem[]> = signal([]);

  boxDetail = input.required<BoxDetail | null>();
  #boxDetail$ = toObservable(this.boxDetail);

  selectedPublishedBundle: WritableSignal<Item | null> = signal(null);
  isFixedPrice = signal(false);
  handleImageError = handleImageError;

  constructor() {
    afterNextRender(() => {
      this.#boxDetail$.pipe(
        tap(value => this.items.set(value?.detail ?? []))
      ).subscribe();

      this.#publishedBundles$.pipe(
        tap(value => {
          this.isFixedPrice.set(value[0].isFixed);
          this.selectedPublishedBundle.set(value[0])
        })
      ).subscribe();
    });
  }

  #setUpItems(items: BundleItems[]) {

    if (!items || !items.length)
      return this.items.set([]);

    this.items.set(items.map(e => {
      return {
        img: e.img,
        productName: e.name,
        quantity: e.quantity,
        packageName: e.packageName || e.display || '',
        producer: e.producerName || '',
        price: e.price
      } as BoxDetailItem
    }));
  }

  #setUpPublishedBundles() {

    const boxDetail = this.boxDetail();
    if (!boxDetail) return [];

    const pbs = boxDetail.publishedBundles?.map(pb => {
      const dateFrom = formatDateToReadableString(pb.dateFrom).mmddyyyyFormat
      const dateEnd = formatDateToReadableString(pb.dateTo).mmddyyyyFormat
      return {
        id: pb.id,
        name: `${dateFrom} to ${dateEnd}`,
        isFixed: pb.isFixed
      } as PublishedBundles
    }) ?? [];

    return pbs;
  }

  setSelectedPublishedBundle(event: MatSelectChange) {
    const option = this.publishedBundles().find(o => o.id === event.value);
    if (option) {
      this.selectedPublishedBundle.set(option);
      this.isFixedPrice.set(option.isFixed);
      this.#getSelectedBundleItems(+option.id);
    }
  }

  #getSelectedBundleItems(bundleId: number) {
    this.#productsService.getBundelItemsById(bundleId).pipe(tap(res => this.#setUpItems(res))).subscribe();
  }


  closeModal() {
    this.#activeModal.close({ addToCart: true });
  }
}
