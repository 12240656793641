import { afterNextRender, Component, computed, inject, Signal, signal, WritableSignal, InputSignal, input, Input } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaskDateDirective } from '../../directives/maskDate.directive';
import { CommonModule } from '@angular/common';
import { MaskCardNumberDirective } from '../../directives/maskCardNumber.directive';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { SignalsStoreService } from '../../signals-store.service';
import { Session } from '../../types/session.type';
import { LocalStorageService } from '../../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { MatRadioModule } from '@angular/material/radio';
import { SuccessAnimatedIcon } from '../../success-animated-icon/success-animated-icon';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ANetPayload, ANetResponse } from '../../purchase/authorize/authorize.types';
import { AuthorizeService } from '../../purchase/authorize/authorize.service';
import { GiftCardService } from '../../../pages/gift-cards/gift-cards.service';
import { GiftData, PurchasePayload } from '../../../pages/gift-cards/gift-card.types';
import { NotificationService } from '../../notification/notification.service';
import { tap } from 'rxjs';
import { GlobalRequestStatus } from '../../common/types';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { TRANSACTION_ERROR, TRANSACTION_SUCCESSFULLY_SENT } from '../../purchase/purchase.constants';
import { unableOperationMessage } from '../../common/utils';
import { ModalContentService } from '../modal-content.service';

@Component({
    selector: 'app-auth-net-purchase',
    imports: [
        MaskDateDirective,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        MaskCardNumberDirective,
        OnlyNumberDirective,
        MatRadioModule,
        SuccessAnimatedIcon,
        EmptyMessageComponent
    ],
    templateUrl: './auth-net-purchase.component.html',
    styleUrl: './auth-net-purchase.component.scss'
})
export class AuthNetPurchaseComponent {

  #formBuilder = inject(FormBuilder);
  #signalsStoreService = inject(SignalsStoreService);
  #localStorageService = inject(LocalStorageService);
  #activeModal = inject(NgbActiveModal);
  #authorizeService = inject(AuthorizeService);
  #giftCardService = inject(GiftCardService);
  #notificationService = inject(NotificationService);
  #modalContentService = inject(ModalContentService);

  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());
  sessionCardNumber: WritableSignal<string> = signal('');
  formattedSessionCardNumber: Signal<string> = computed(() => this.#formatSessionCardNumber());
  wasRequestTriggered: WritableSignal<boolean> = signal(false);
  isResponseFinished: WritableSignal<boolean> = signal(false);
  isResponseSucces: WritableSignal<boolean> = signal(false);
  isResponseError: WritableSignal<boolean> = signal(false);
  responseMessage: WritableSignal<string> = signal('');

  shouldCloseOnPurchaseFinished: InputSignal<boolean> = input(false);
  purchaseSummary: InputSignal<string> = input('');
  purchaseReqAddFields: InputSignal<GiftData | null> = input<GiftData | null>(null);

  purchaseForm = this.#formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    cardNumber: ['', [Validators.required, Validators.maxLength(25)]],
    expDate: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/(\d{4}|\d{4})$/)]],
    cardCode: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(3)]]
  })

  constructor() {
    afterNextRender(() => {
      this.#setUpSessionCreditCardNumber()
    })
  }

  #setUpSessionCreditCardNumber() {
    if (!this.hasSession()) return;
    const session: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    this.sessionCardNumber.set(session?.creditCard?.number ?? '');
  }

  #formatSessionCardNumber() {
    const sessionCardNumber = this.sessionCardNumber();
    if (!sessionCardNumber) return '';
    return sessionCardNumber.padStart(29, '**** ');
  }

  validateErrors(controlName: string): string | null {
    const control = this.purchaseForm.get(controlName);
    if (control?.valid || (!control?.dirty && !control?.touched)) return null;
    if (control?.hasError('required')) {
      return `This field is required`
    } else if (control?.hasError('minlength')) {
      return `This field must have at least ${control?.errors?.['minlength']?.requiredLength} digits.`
    } else if (control?.hasError('maxlength')) {
      return `This field can have a maximum of ${control?.errors?.['maxlength']?.requiredLength} digits.`
    } else if (control?.hasError('pattern')) {
      if (controlName === 'expDate') {
        return 'This field must be in the format MM/YYYY.'
      }
      return 'The format of this field is incorrect.'
    }
    return null;
  }

  confirmPurchase() {
    if (this.sessionCardNumber())
      return this.#sendPurchaseWithSession();

    if (!this.purchaseForm.valid) return this.purchaseForm.markAllAsTouched();

    const value = this.purchaseForm.value;
    if (!value.expDate ||
      !value.cardCode ||
      !value.cardNumber
    ) return this.purchaseForm.markAllAsTouched();
    const [month, year] = value.expDate.split('/');
    const ANetData: ANetPayload = {
      cardCode: value.cardCode?.toString(),
      cardNumber: value.cardNumber.replaceAll('-', ''),
      year,
      month
    };
    this.#authorizeService.sendPaymentDataToAnet(ANetData, (response: ANetResponse) => this.#sendPurchase(response))
  }

  #sendPurchaseWithSession() {
    this.wasRequestTriggered.set(true);
    const payload = this.#setUpPayloadWithSession();
    if (!payload) return unableOperationMessage(this.#modalContentService);
    this.#giftCardService.sendPurchase(payload).pipe(
      tap(res => this.#handlePurchaseResponse(res))
    ).subscribe();
  }

  #sendPurchase(response: ANetResponse) {
    this.wasRequestTriggered.set(true);
    const payload = this.#setUpPayload(response);

    if (!payload) return this.#notificationService.show({ text: 'Validate mandatory fields', type: 'error' });
    this.#giftCardService.sendPurchase(payload).pipe(
      tap(res => this.#handlePurchaseResponse(res))
    ).subscribe();

  }

  #handlePurchaseResponse(res: GlobalRequestStatus) {
    this.isResponseFinished.set(res.finished);
    this.isResponseSucces.set(res.success);
    this.isResponseError.set(res.error);
    this.responseMessage.set(res.error ? TRANSACTION_ERROR : TRANSACTION_SUCCESSFULLY_SENT);
  }

  #setUpPayload(opaqueData: ANetResponse): PurchasePayload | null {
    if (!this.purchaseForm.valid) {
      this.purchaseForm.markAllAsTouched();
      return null;
    }

    const value = this.purchaseForm.value;
    if (!value.cardCode || !value.firstName || !value.lastName || !value.cardNumber || !value.expDate) {
      this.purchaseForm.markAllAsTouched();
      return null;
    }

    const normalizedCardNumber = value.cardNumber.replaceAll('-', '');
    const cardLastNumbers = normalizedCardNumber.substring(normalizedCardNumber.length - 4, normalizedCardNumber.length);
    const [month, year] = value.expDate.split('/');

    const fields = this.purchaseReqAddFields()
    if (!fields) return null;

    const { senderName, senderEmail, recipientName, recipientEmail, variantId, personalMessage } = fields;

    return {
      card: {
        firstName: value.firstName,
        lastName: value.lastName,
        number: cardLastNumbers,
        expirationDate: `${year}-${month}`
      },
      opaqueData,
      giftData: {
        senderName,
        senderEmail,
        recipientName,
        recipientEmail,
        personalMessage,
        variantId
      }
    } as PurchasePayload
  }

  #setUpPayloadWithSession(): PurchasePayload | null {
    const fields = this.purchaseReqAddFields()
    if (!fields) return null;

    const { senderName, senderEmail, recipientName, recipientEmail, variantId, personalMessage } = fields;

    return {
      giftData: {
        senderName,
        senderEmail,
        recipientName,
        recipientEmail,
        personalMessage,
        variantId
      }
    } as PurchasePayload
  }

  closeModal() {
    this.#activeModal.close();
  }

}
