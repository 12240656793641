import { BundleEditionTypes } from "../app/shared/types/order.type";
import { BundleModifyTypes, PurchaseTypes } from "../app/shared/types/flows-config.types";

const base_api = "https://farmboxtx.navego.tech";

export const environment = {
  production: true,
  apis: {
    v1: `${base_api}`,
    v2: `${base_api}/api/v2`,
    v3: `${base_api}/api/v3`,
    google: {
      places: 'AIzaSyBTHTviZ7QPtEjH29Jmmkp13RO8LPrxppM'
    },
    navego: {
      url: 'https://apis.navego.tech/api',
      key: 'dsfgrdf125fdffasd677rrfgr',
      clientNr: '9'
    },
    firebase: {
      apiKey: "AIzaSyBiwOw5qRGmaaoHFvbsONQycpt8eWOjK9A",
      authDomain: "prod-fmbx-tx.firebaseapp.com",
      projectId: "prod-fmbx-tx",
      storageBucket: "prod-fmbx-tx.firebasestorage.app",
      messagingSenderId: "358701734425",
      appId: "1:358701734425:web:a337b1955474966f766888",
      measurementId: "G-BD9R30H2BB"
    },
    fireauth: {
      email: 'javier.franco@eliostudios.co',
      password: '@N4v3g0.T3ch.2024**'
    },
    klaviyo: {
      publicApiKey: 'Ttu4Lu'
    },
    maintenance: {
      url: 'https://ecommerce-configurations.navego.tech/api/v3/maintenance?url=farmboxtx'
    },
    authorize: {
      APILoginID: '5Tq4cC7gw',
      PublicClientkey: '5vhdZguRPHgM4pjU8w6VBvzX7RX9XmyG6sh86TqtCZs34Zs2CR8Tq8D77N6q8MYt'
    }
  },
  application: {
    angular: 'Angular 17.3.7',
    bootstrap: 'Bootstrap 5.3.2',
    fontawesome: 'Font Awesome 6.5.1',
    name: 'Farmbox Delivery',
    version: '1.0.0',
  },
  config: {
    bodyClass: 'app-me',
    crypto: {
      key: '!!!3nCrypt3d???',
    },
    flows: {
      territories: false,
      membership: false,
      settings: {
        toHide: {
          membership: 'Membership'
        },
        profile: {
          hasPickupLocationsFlow: true
        },
        deliveryDayUpdates: {
          displayOnlyActiveReescheduledOrder: true
        }
      },
      specialMembership: {
        defaultRoute: '/order/custom-box/' + BundleEditionTypes.subscription,
        settings: {
          hideHeaderCategoriesNav: true
        },
      },
      order: {
        askForDonationOnSkip: false,
        showFreeDeliverySlider: true,
        voluntaryDonationFlow: false,
      },
      bundles: {
        modifyType: BundleModifyTypes.outOfOrder
      },
      shop: {
        validateMarketState: true,
        displayCategoryName: true
      },
      login: {
        tempResetPassword: true
      },
      purchase: {
        type: PurchaseTypes.anet
      },
      productDetail: {
        showMultipleImages: false
      }
    },
    filesVersionNumber: {
      logo: 2
    },
    friendReferral: {
      baseUrl: 'https://farmboxdelivery.com/signup?utm_source=friend_referral&utm_medium=share&utm_campaign='
    },
    seo: {
      mainTitle: "Local Produce and Food Delivery in Texas | Farmbox Delivery",
      mainDescription: "Discover Farmbox Texas: Fresh local produce, dairy, and pasture-raised meats delivered straight to your door. Supporting Texas farmers and sustainable practices.",
      baseTitle: "Farmbox Texas Fresh Local Produce and Food Delivery",
      baseKeywords: ["local", "produce", "dairy", "pasture-raised meats", "food delivery", "Texas", "Farmbox Texas"],
      type: "website",
      baseURL: "https://farmboxdelivery.com/"
    },
    contactEmail: 'info@farmboxdl.com',
    bundleCategoryId: 987
  },
};
