import { inject, Injectable } from '@angular/core';
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { addDoc, collection, deleteDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db, ensureAuthenticated } from './firebase-config';
import { LOCALSTORAGE_KEYS } from './constants/databases';
import { Session } from './types/session.type';
import { LocalStorageService } from './local-storage.service';
import * as Sentry from '@sentry/angular';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCrudService {


  #localStorageService = inject(LocalStorageService);

  // Método para obtener un documento por su ID de una colección
  async getById(collection: string, id: string, callback: (data: any | null) => void): Promise<any> {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'getById', collection, id, });
      return null;
    }

    const docRef = doc(db, collection, id); // Crear la referencia del documento

    return onSnapshot(docRef, (docSnap) => docSnap.exists() ? callback({ id: docSnap.id, ...docSnap.data() }) : callback(null))
  }

  getByIdNoValueChange(collection: string, id: string): Observable<any> {
    return from(
      (async () => {
        const user = await ensureAuthenticated();
        if (!user) {
          this.#trackAuthError({ method: 'getByIdNoValueChange', collection, id });
          return null;
        }

        const docRef = doc(db, collection, id);
        const docSnap = await getDoc(docRef);

        return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
      })()
    );
  }

  // Método para agregar un nuevo documento a una colección
  async add(collection: string, docId: string | number, data: any) {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'add', collection, id: docId });
      return null;
    }

    const docRef = doc(db, `${collection}/${docId}`)
    return setDoc(docRef, data);
  }

  async addWithoutDocId(collectionName: string, data: any) {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'addWithoutDocId', collection: collectionName });
      return;
    }

    const collectionRef = collection(db, collectionName);
    addDoc(collectionRef, data);
  }

  // Método para actualizar un documento existente en una colección
  async update(collection: string, id: string | number, data: any): Promise<void> {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'update', collection, id, });
      return;
    }

    const exists = await this.existsDoc(collection, id.toString());
    if (!exists)
      return Promise.resolve();

    const docRef = doc(db, collection, id.toString());
    updateDoc(docRef, data);
  }

  async updateSubkeys(payload: FirebaseUpdateSubKeys): Promise<void> {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'updateSubkeys', collection: payload.collection, id: payload.docId });
      return;
    }

    const { collection, docId, updateData } = payload;

    const exists = await this.existsDoc(collection, docId);
    if (!exists)
      return Promise.resolve();

    const docRef = doc(db, collection, docId.toString());
    return updateDoc(docRef, updateData);
  }

  async updateSubkey(payload: FirebaseUpdateSubKey): Promise<void> {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'updateSubkey', collection: payload.collection, id: payload.docId });
      return Promise.resolve();
    }

    const { collection, docId, subKeyPath, newValue } = payload

    const exists = await this.existsDoc(collection, docId);
    if (!exists)
      return Promise.resolve();


    const updateData: any = {};
    updateData[subKeyPath] = newValue;  // Creamos un objeto con la subclave y su nuevo valor
    const docRef = doc(db, collection, docId.toString());
    return updateDoc(docRef, updateData);
  }

  async existsDoc(collectionName: string, docId: string): Promise<boolean> {
    try {
      const user = await ensureAuthenticated();
      if (!user) {
        this.#trackAuthError({ method: 'existsDoc', collection: collectionName, id: docId });
        return Promise.resolve(false);
      }

      const docRef = doc(db, collectionName, docId);
      const snapshot = await getDoc(docRef);
      return Promise.resolve(snapshot.exists()); // ✅ Retorna true si existe
    } catch (error) {
      console.error('Error al verificar existencia del documento:', error);
      return Promise.resolve(false); // Retorna `false` en caso de error
    }
  }

  // Método para eliminar un documento de una colección
  async delete(collection: string, id: string): Promise<void> {
    const user = await ensureAuthenticated();
    if (!user) {
      this.#trackAuthError({ method: 'delete', collection, id });
      return Promise.resolve();
    }

    const docRef = doc(db, collection, id);
    return deleteDoc(docRef);
  }

  #trackAuthError(paylaod: { method: string, collection: string, id?: string | number }) {
    const userSessionEmail = this.#getUserSessionEmail();
    const sentryPayload = {
      ...paylaod,
      userSessionEmail
    };

    Sentry.setContext('firebase', { ...sentryPayload, errorMessage: 'CUSTOM >> User authentication failed' });
    Sentry.captureException(new Error('CUSTOM >> User authentication failed'));
  }

  #getUserSessionEmail() {
    const userSession: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null
    if (!userSession) return '';

    return userSession?.accountInfo?.email ?? '';
  }
}

export interface FirebaseUpdateSubKey {
  collection: string;
  docId: string;
  subKeyPath: string;
  newValue: any;
}

export interface FirebaseUpdateSubKeys {
  collection: string;
  docId: string;
  updateData: any;
}
