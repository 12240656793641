import { AfterViewInit, Component, ElementRef, NgZone, ViewChild, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressService, NavegoAddressRequest } from '../../address.service';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMapsUtils } from '../../common/utils';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { environment } from '../../../../environments/environment';
import { SignalsStoreService } from '../../signals-store.service';
import { LocalStorageService } from '../../local-storage.service';
import { FormsModule } from '@angular/forms';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { tap } from 'rxjs';

@Component({
    selector: 'app-check-address',
    imports: [MatProgressBarModule, FormsModule],
    providers: [{
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.apis.google.places,
                libraries: ['places'],
                region: 'US',
            })
        }],
    templateUrl: './check-address.component.html',
    styleUrl: './check-address.component.scss'
})
export class CheckAddressComponent implements AfterViewInit {

  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef<HTMLInputElement>;
  localStorageService = inject(LocalStorageService)

  private router = inject(Router);
  private activeModal = inject(NgbActiveModal)
  private addressService = inject(AddressService);
  private zone = inject(NgZone);
  private loader = inject(Loader);
  private signalsStoreService = inject(SignalsStoreService);

  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading());
  hasDeliveryAddressCoverage: any = signal(false);

  queryString = ''

  ngAfterViewInit(): void {
    const locationText: string | null =
      this.signalsStoreService.anonymousUserAddress()?.street ?? '';
    if (locationText) this.queryString = locationText;

    GoogleMapsUtils.initializeAutocomplete(this.loader, this.autocompleteInput.nativeElement, this.onPlaceSelected.bind(this));
    GoogleMapsUtils.loadAutoCompleteIfAny(
      this.loader,
      this.onPlaceSelected.bind(this),
      this.localStorageService
    );
  }

  loginClickHandler() {
    this.activeModal.close();
    this.router.navigate(['/login']);
  }

  private onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.zone.run(() => {
      const {
        city,
        stateCode,
        zipCode,
        name: street,
        latitude,
        longitude,
        formattedAddress,
        placeId
      } = this.addressService.setupDeliveryData(place);

      this.signalsStoreService.googleAddress.set({
        street,
        city,
        stateCode,
        zipCode,
        latitude,
        longitude,
        placeId,
      });

      this.localStorageService.set(LOCALSTORAGE_KEYS.GOOGLEPLACERESULT, place);
    });


  }

  checkDeliveryAddressCoverage() {
    this.isGoogleAddressFilled.set(true);
    const { street, city, stateCode, latitude, longitude } = this.signalsStoreService.googleAddress();
    const navegoAddressRequest = {
      street,
      city,
      state: stateCode,
      latitude,
      longitude,
    } as NavegoAddressRequest;

    this.addressService.checkDeliveryAddress(navegoAddressRequest, true, true).pipe(
      tap(() => {
        if (!!this.addressService.navegoAddress()) {
          setTimeout(() => {
            this.closeModal();
            this.router.navigate(['/signup'])
          }, 1000);
        } else {
          this.localStorageService.remove(LOCALSTORAGE_KEYS.GOOGLEPLACERESULT);
        }

        this.hasDeliveryAddressCoverage.set(!!this.addressService.navegoAddress());
      })
    ).subscribe()
  }

  changeAddress() {
    this.hasDeliveryAddressCoverage = signal(false);
    this.isGoogleAddressFilled.set(false);
  }

  closeModal() {
    this.activeModal.close(true);
  }

}
