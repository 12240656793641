<div class="d-grid gap-2 col-8 mx-auto">
  <div class="col-md-auto">
    <h2 class="modal-title text-center">Update your order</h2>
  </div>
</div>

<div class="row row-cols-1 justify-content-sm-center mt-3">
  <div class="col-12">
    <div class="d-flex align-items-center text-center mb-2">
      <p>
        Your order has not been submitted yet. Please save your order! Remember,
        you can continue to update and edit this order until your cut-off
        day/time at
        {{ deliveryInfo()?.cutoffDate?.month }}
        {{ deliveryInfo()?.cutoffDate?.day }},
        {{ deliveryInfo()?.cutoffTime }}
      </p>
    </div>
  </div>
</div>

<div class="row justify-content-md-center mt-4">
  <div class="col-6 mb-2 mb-sm-0">
    <button type="button" class="btn btn-outline-primary w-100 h-100" (click)="closeModal()">
      Edit Order
    </button>
  </div>
  <div class="col-6">
    <button type="button" class="btn btn-primary w-100" (click)="execute()">
      Update/Submit Order
    </button>
  </div>
  <div class="col-12 mt-2 text-center">
    <a href="javascript:void(0)" (click)="closeModal(true)" class="link-primary">Continue shopping</a>
  </div>
</div>