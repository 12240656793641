export interface DataLayer_Event<T> {
    event: DATA_LAYER_EVENT;
    data?: T,
    user?: DataLayer_User
}

export interface DataLayer_FormSubmission {
    form_id: DATA_LAYER_FORM_SUBMISSION_ID_OPTIONS
}

interface DataLayer_Products {
    ecommerce: DataLayer_Ecommerce;
}

export interface DataLayer_ViewItem extends DataLayer_Products { }
export interface DataLayer_AddToCart extends DataLayer_Products { }
export interface DataLayer_BeginCheckout extends DataLayer_Products { }

export interface DataLayer_ViewItemList {
    ecommerce: {
        item_list_name: string;
        items: DataLayer_CartItem[];
    },
}

export interface DataLayer_Ecommerce {
    currency: DATA_LAYER_ECOMMERCE_CURRENCY_OPTIONS;
    value: number;
    tax?: number;
    shipping?: number;
    tip?: number;
    coupon?: string;
    items: DataLayer_CartItem[];
}

export interface DataLayer_CartItem {
    item_id: string;
    item_name: string;
    price: number;
    discount: number;
    item_category: string;
    item_category2: string;
    item_brand?: string;
    promotion?: string;
    promotion_name?: string;
    quantity: number;
}

export interface DataLayer_User {
    email: string;
    phone?: string;
    first_name?: string;
    last_name?: string;
    user_id?: string;
}

export interface DataLayer_Subscribe {
    subscription_name: string;
    quantity: number;
    subscription_value: number;
}

export interface DataLayer_CheckAddress {
    delivery_area: boolean;
}

export interface DataLayer_LoginState {
    status: DATA_LAYER_LOGIN_STATE_OPTIONS;
}

//#region Enums:

export enum DATA_LAYER_EVENT {
    login = 'login', // ok
    sign_up = 'sign_up', // ok
    login_state = 'login_state', // ok
    check_address = 'check_address', // ok
    form_submission = 'form_submission', // ok
    subscribe = 'subscribe', // ok
    view_item_list = 'view_item_list', // ok
    view_item = 'view_item', // ok
    add_to_cart = 'add_to_cart', // ok
    begin_checkout = 'begin_checkout', // partially - remains to validate the key "value"
}

export enum DATA_LAYER_FORM_SUBMISSION_ID_OPTIONS {
    newsletter = 'newsletter',
    delivery_area_update = 'delivery_area_update'
}

export enum DATA_LAYER_ECOMMERCE_CURRENCY_OPTIONS {
    usd = 'USD'
}

export enum DATA_LAYER_LOGIN_STATE_OPTIONS {
    logged_in = 'logged_in'
}

//#endregion