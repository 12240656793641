import { Component, computed, inject } from '@angular/core';
import { BasicBundleInfo } from '../../types/order.type';
import { Router } from '@angular/router';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignalsStoreService } from '../../signals-store.service';

@Component({
    selector: 'app-bundles-subscription',
    imports: [
        EmptyMessageComponent
    ],
    templateUrl: './bundles-subscription.component.html',
    styleUrl: './bundles-subscription.component.scss'
})
export class BundlesSubscriptionComponent {

  //#region Services

  #router = inject(Router);

  #activeModal = inject(NgbActiveModal);

  #signalsStoreService = inject(SignalsStoreService);

  //#endregion

  //#region Properties

  bundles = computed(() => this.#signalsStoreService.getBundlesLikeSubscriptions());

  //#endregion

  //#region Methods

  goToCustomBox(bundle: BasicBundleInfo) {
    this.#activeModal.close();
    this.#router.navigate([`/shop/custom-box/subscription/${bundle.id}`]);
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  //#endregion
}
