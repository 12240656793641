@if (publishedBundles().length > 1) {
<div class="d-lg-flex d-md-grid d-sm-grid gap-sm-2 align-items-center col-lg-6 col-md-6 col-12 mx-auto my-lg-5 my-3">
  <p class="text-center m-0" [class.w-50]="isDesktop()" [class.w-100]="!isDesktop()">Contents for the week of:</p>
  <mat-form-field class="custom-drop-down" [class.w-50]="isDesktop()" [class.w-100]="isMobile()">
    <mat-select name="selectPublishedBundle" [value]="selectedPublishedBundle()?.id"
      (selectionChange)="setSelectedPublishedBundle($event)">
      @for (item of publishedBundles(); track $index) {
      <mat-option [value]="item.id">{{item.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
} @else {
<div class="d-grid gap-2 col-lg-12 col-md-12 mx-auto">
  <div class="text-center mt-2 animated fadeIn mb-5">
    <p>Contents for the week of {{boxDetail()?.startDate}} to {{boxDetail()?.endDate}}</p>
  </div>
</div>
}

<div class="row">
  @for(product of items(); track $index) {
  <div class="col-sm-6">
    <div class="card-box">
      <div class="card-box__img">
        <img (error)="handleImageError($event)" [src]="product?.img || 'assets/images/product-placeholder.webp'"
          class="img-product" alt="...">
      </div>
      <div class="card-box__detail">
        <h5 class="card-box__title">{{product.productName}}</h5>
        <p class="card-box__quantity">{{product.packageName}}</p>
        <p class="card-box__quantity">Quantity: {{product.quantity}}</p>
        <p class="card-box__sub-title">{{product.producer}}</p>
        @if (!isFixedPrice()) {
        <h5 class="card-box__price">{{product.price | currency}}</h5>
        }
      </div>
    </div>
  </div>
  }
</div>

@if (this.items().length) {
<div class="d-grid gap-2 col-6 mx-auto mt-4">
  @if(!boxDetail()?.existsInOrder) {
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    {{boxDetail()?.isSubscription ? ' Subscribe' : ' Add to cart'}}</button>
  }
</div>
}