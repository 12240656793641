import { Component, input, InputSignal } from '@angular/core';
import { OrderRescheduled } from '../../../settings/account/profile/profile.types';

@Component({
    selector: 'app-rescheduled-orders',
    imports: [],
    templateUrl: './rescheduled-orders.component.html',
    styleUrl: './rescheduled-orders.component.scss'
})
export class RescheduledOrdersComponent {
  orders: InputSignal<OrderRescheduled[] | undefined> = input();
}
