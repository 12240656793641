import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { tap } from 'rxjs';
import { OrderHistoryResponse, OrderHistoryDetailResponse, OrderHistoryProductResponse, BillingDetail, BillingDetailItem, BuyAgainProducts, BuyAgainResponse } from "./order-and-billing.types";
import { ApiResponse } from "../../../shared/common/types";
import { NotificationService } from "../../../shared/notification/notification.service";
import { ORDER_STATUS_CLASSES, PAYMENT_STATUS_CLASSES } from "./order-and-billing.constants";
import { OrderService } from "../../../shared/order.service";

@Injectable()
export class OrderHistoryService {
    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    private orderService = inject(OrderService);

    private endpoints = {
        base: '/account/order-history',
        buyAgain: '/buy-again'
    };

    orderHistory: WritableSignal<OrderHistoryResponse[]> = signal([]);
    orderHistoryDetail: WritableSignal<OrderHistoryDetailResponse | null> = signal(null);
    products: WritableSignal<OrderHistoryProductResponse[]> = signal([]);
    billinDetail: WritableSignal<BillingDetailItem[]> = signal([]);

    getOrderHistory(filter: { startDate: string, endDate: string } | null = null) {
        const params: RequestHandlerParams = {
            endpoint: filter ? `${this.endpoints.base}?startDate=${filter.startDate}&endDate=${filter.endDate}` : this.endpoints.base,
            method: 'GET',
            apiV3: true,
            showErrorMessage: false
        };

        this.apiService.handleRequest<ApiResponse<OrderHistoryResponse[]>>(params).pipe(
            tap((response: ApiResponse<OrderHistoryResponse[]>) => this.setUpOrderHistoryData(response.data))
        ).subscribe();
    }

    getOrderHistoryDetail(id: number) {
        const params: RequestHandlerParams = {
            endpoint: `${this.endpoints.base}/${id}`,
            method: 'GET',
            apiV3: true
        };

        this.apiService.handleRequest<ApiResponse<OrderHistoryDetailResponse>>(params).pipe(
            tap((response: ApiResponse<OrderHistoryDetailResponse>) => {
                this.setUpData(response.data);
                this.products.set(response.data.products.map(p => {
                    p.selected = !p.isSubscription && p.hasStock;
                    return p;
                }));
            })
        ).subscribe();
    }

    buyAgainProducts(body: BuyAgainProducts[]) {
        const params: RequestHandlerParams = {
            endpoint: `${this.endpoints.base}${this.endpoints.buyAgain}`,
            method: 'POST',
            apiV3: true,
            body
        };

        return this.apiService.handleRequest<ApiResponse<BuyAgainResponse>>(params).pipe(
            tap((response: ApiResponse<BuyAgainResponse>) => {
                if (response.data.hasAllProductsAdded)
                    this.notificationService.show({ text: response.message, type: 'success' });
                if (response.data.order) {
                    this.orderService.odooOrder.set(response.data.order);
                }
            })
        )
    }

    private setUpOrderHistoryData(data: OrderHistoryResponse[]) {
        this.orderHistory.set(data.map(e => {
            e.orderStatusClass = ORDER_STATUS_CLASSES[e.orderStatus?.toLowerCase()];
            e.paymentStatusClass = PAYMENT_STATUS_CLASSES[e.paymentStatus?.toLowerCase()];
            return e;
        }));
    }

    private setUpData(data: OrderHistoryDetailResponse) {
        this.setUpOrderHistoryDetailData(data);
        this.setUpBillingDetail(data.billing, data.products);
    }

    private setUpOrderHistoryDetailData(data: OrderHistoryDetailResponse) {
        const splittedDeliveryDate = data.deliveryDate.split('-');
        const deliverytDate = `${splittedDeliveryDate ? splittedDeliveryDate[1] : '--'}/${splittedDeliveryDate ? splittedDeliveryDate[2] : '--'}/${splittedDeliveryDate ? splittedDeliveryDate[0] : '----'}`;
        data.deliveryDate = deliverytDate;
        data.payment.cardNumber = (data.payment.cardNumber || '').padStart(19, '**** ');
        data.orderStatusClass = ORDER_STATUS_CLASSES[data.orderStatus?.toLowerCase()];
        data.payment.class = PAYMENT_STATUS_CLASSES[data.payment.status?.toLowerCase()];

        data.products = data.products.map(p => {
            p.isViewAllItems = signal(false);
            p.items = p.items.map(i => {
                const fullNameArray = [i.name];
                if (i.display) fullNameArray.push(i.display);
                if (i.packageName) fullNameArray.push(i.packageName);
                i.fullName = fullNameArray.join(' | ');
                return i;
            }) ?? [];
            p.itemsInView = computed(() => {
                const isShowingAll = p.isViewAllItems();
                return isShowingAll ? p.items : p.items.slice(0, 3)
            })

            return p;
        })
        this.orderHistoryDetail.set(data);
    }

    private setUpBillingDetail(detail: BillingDetail, products: OrderHistoryProductResponse[]) {
        this.billinDetail.set([
            {
                name: 'Subtotal',
                amount: products.reduce((accumulator: number, current: OrderHistoryProductResponse) => accumulator + current.total, 0),
                bold: true,
                greenColor: false
            },
            {
                name: 'Taxes',
                amount: detail.taxes || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Delivery',
                amount: detail.deliveryFee || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Handling Fee',
                amount: detail.handlingFee || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Tip',
                amount: detail.driverTip || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Donation',
                amount: detail.donation || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Friend referral',
                amount: detail.friendReferral || 0,
                bold: false,
                greenColor: false
            },
            {
                name: 'Credits',
                amount: detail.credit || 0,
                bold: false,
                greenColor: true
            },
            {
                name: 'Total',
                amount: detail.total || 0,
                bold: true,
                greenColor: false
            },
        ]);
    }
}
