import { Inject, Injectable, PLATFORM_ID, WritableSignal, inject, signal } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { finalize, tap } from 'rxjs';
import { LocalStorageService } from '../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { ApiService } from '../shared/api.service';
import { RequestHandlerParams } from '../shared/types/api-service.types';
import { ApiResponse } from '../shared/common/types';
import { AccountService } from '../shared/account.service';
import { Router } from '@angular/router';
import { KlaviyoService } from '../shared/klaviyo/klaviyo.service';
import { NotificationService } from '../shared/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private localStorageService = inject(LocalStorageService);
  private apiService = inject(ApiService);
  private accountService = inject(AccountService);
  #router = inject(Router);
  #notificationService = inject(NotificationService);
  #klaviyoService = inject(KlaviyoService);

  endpoints = {
    login: `/login`
  }

  isLoadingLogin: WritableSignal<boolean> = signal(false);

  constructor(
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }


  login(email: string, password: string, hasExtendedSession: boolean = false) {

    this.isLoadingLogin.set(true)

    const params: RequestHandlerParams = {
      endpoint: this.endpoints.login,
      method: 'POST',
      body: { email, password, hasExtendedSession },
      apiV3: true
    }

    this.localStorageService.set(LOCALSTORAGE_KEYS.NO_PASSWORD_USER_EMAIL, email);

    this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {
        if (response.noPassword) {
          this.location.replaceState('/account-recovery/new-ecommerce');
          window.location.reload();
          return;
        }
        this.localStorageService.remove(LOCALSTORAGE_KEYS.NO_PASSWORD_USER_EMAIL);
        const { data } = response;
        this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, { ...data });
        this.localStorageService.set(LOCALSTORAGE_KEYS.FROM_LOGIN, true);

        // Validate if exists a stored route to navigate after login:
        const routeToNavigateAfterLogin: string = this.localStorageService.get(LOCALSTORAGE_KEYS.ROUTE_TO_NAVIGATE_AFTER_LOGIN) || '/shop';
        this.localStorageService.remove(LOCALSTORAGE_KEYS.ROUTE_TO_NAVIGATE_AFTER_LOGIN);

        // // Get memebership permissions:
        this.accountService.getMembershipPermissions(routeToNavigateAfterLogin, true).subscribe();
      }),
      finalize(() => this.isLoadingLogin.set(false))
    ).subscribe();
  }

  logout() {
    if (!isPlatformBrowser(this.platformId)) return;
    localStorage.clear();
    this.reloadPage('/');
  }

  private reloadPage(route: string) {
    this.location.replaceState(route);
    window.location.reload();
  }

  loginAsAdmin(token: string) {
    this.localStorageService.clear();

    const params: RequestHandlerParams = {
      endpoint: '/login-admin',
      method: 'GET',
      apiV3: true,
      returnError: true,
      token
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap(res => {
        if (res.error) {
          this.#notificationService.show({ text: res.message ?? 'There was an error trying to authenticate the request', type: 'error' })
          this.#router.navigate(['/']);
          return;
        }
        const { data } = res;
        this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, { ...data });
        this.#klaviyoService.identiyUser();
        this.accountService.getMembershipPermissions('/shop', true).subscribe();
      })
    );
  }
}
