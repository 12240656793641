import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignalsStoreService } from '../../signals-store.service';
import { formatCurrency, formatCurrencyToAmount } from '../../utils/formatting';
import { CurrencyFormatDirective } from '../../directives/currency-format.directive';
import { ResolutionService } from '../../resolution.service';

@Component({
    selector: 'app-donation-skippable',
    imports: [MatRadioModule, CommonModule, FormsModule, MatFormField, MatLabel, CurrencyFormatDirective],
    templateUrl: './donation-skippable.component.html',
    styleUrl: './donation-skippable.component.scss'
})
export class DonationSkippableComponent {
  private activeModal = inject(NgbActiveModal);
  signalsStoreService = inject(SignalsStoreService)
  private resolutionService = inject(ResolutionService);

  currentDonationAmount = +this.signalsStoreService.totalOrderAmount().toFixed(2)
  donationAmount = computed( () => formatCurrency(this.signalsStoreService.donationAmountSkippable()) )
  selectedOption: string = 'DONATE_FULL_AMOUNT';

  isMobile = computed(() => this.resolutionService.isMobile());

  donate(){
    const selectedDonationAmount = this.selectedOption === 'DONATE_FULL_AMOUNT' 
                                    ? this.currentDonationAmount
                                    : this.signalsStoreService.donationAmountSkippable();
    const amount = formatCurrencyToAmount(selectedDonationAmount)
      
    this.signalsStoreService.donationAmountSkippable.set(amount)
    this.activeModal.close({
      isDonationCanceled: false,
      donationAmount: amount
    });
  }
  
  closeModal() {
    this.signalsStoreService.donationAmountSkippable.set(0.00)
    this.activeModal.close({
      isDonationCanceled: false,
      donationAmount: 0.00
    });
  }

  cancel() {
    this.activeModal.close({
      isDonationCanceled: true
    });
  }
}
