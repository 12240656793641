import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { environment } from '../../environments/environment';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import * as Sentry from '@sentry/angular';

// Inicializar Firebase antes de usar Firestore
const firebaseApp = initializeApp(environment.apis.firebase);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

// Método para autenticación anónima
async function ensureAuthenticated(maxRetries = 2): Promise<any> {
    let retries = 0;

    while (retries < maxRetries) {
        try {
            // Si ya hay un usuario autenticado, lo retornamos inmediatamente.
            if (auth.currentUser)
                return auth.currentUser;

            // Intentar autenticarse de forma anónima
            await signInAnonymously(auth);

            // Esperar hasta que auth.currentUser se actualice usando onAuthStateChanged
            return new Promise((resolve, reject) => {
                const unsubscribe = onAuthStateChanged(auth, (user) => {
                    if (user) {
                        unsubscribe();
                        resolve(user);
                    }
                }, reject);
            });
        } catch (error: any) {
            console.error("Error during anonymous sign-in, retrying...", error);
            Sentry.setContext('firebase', { errorMessage: error.message, errorStack: error.stack });
            Sentry.captureException(new Error(`CUSTOM >> User authentication failed from FIREBASE CONFIG - ${error.message}`));
            retries++;
            // Esperar 1 segundo antes de reintentar
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    // Si se exceden los reintentos, se lanza un error.
    throw new Error("Failed to authenticate after several retries.");
}


export { db, auth, ensureAuthenticated };