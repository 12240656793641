import { Component, Input, computed, inject, signal } from '@angular/core';
import { BecomeAMemeber } from '../modal-content';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MembershipService } from '../../../settings/billing/membership/membership.service';

@Component({
    selector: 'app-become-a-member',
    imports: [CommonModule],
    providers: [MembershipService],
    templateUrl: './become-a-member.component.html',
    styleUrl: './become-a-member.component.scss'
})
export class BecomeAMemberComponent {

  private activeModal = inject(NgbActiveModal);

  @Input()
  set data(value: BecomeAMemeber | undefined) {
    if (value) {
      this._data = value;
      this.depositValue.set(value.deposit);
      this.membershipPrice.set(value.membershipCost || 0);
      this.membershipFrequency.set(value.membershipFrequency);
      this.membershipName.set(value.membershipName);
    }
  }

  get data(): BecomeAMemeber | undefined {
    return this._data;
  }

  private _data!: BecomeAMemeber | undefined

  membershipPrice = signal(0);
  depositValue = signal(0);
  membershipFrequency = signal('');
  membershipName = signal('');
  total = computed(() => this.membershipPrice() + this.depositValue());

  closeModal(subscribe: boolean) {
    const deposit = subscribe ? { deposit: this.depositValue() } : null
    this.activeModal.close(deposit);
  }

}
