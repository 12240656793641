import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isShowGlobalLoading = signal(false);

  #allowUrl = ['/order', '/account'];

  constructor() { }

  validateEndPoint(url: string) {
    if (!this.#allowUrl.some(x => url.includes(x)))
      return;

    this.isShowGlobalLoading.set(true);
  }

  close() {
    this.isShowGlobalLoading.set(false);
  }
}
