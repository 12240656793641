import { Injectable, WritableSignal, signal } from "@angular/core";
import { Sidebar, SidebarCheckList, SidebarFilterActions, SidebarFilterTypes } from './sidebar.types';
import { splitByUpperCase } from "../common/utils";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebar: WritableSignal<SidebarCheckList[]> = signal([]);
  #sidebarPersistency: WritableSignal<SidebarCheckList[]> = signal([]);

  #categoryToNavigate: BehaviorSubject<SidebarCheckList> = new BehaviorSubject({} as SidebarCheckList);
  categoryToNavigate$ = this.#categoryToNavigate.asObservable();

  setUpSidebarData(
    data: {
      sidebarData: Sidebar | any,
      categoryId: number,
      filterType?: SidebarFilterActions,
      isFirstTime?: boolean,
    }
  ) {
    const { sidebarData, isFirstTime, categoryId } = data;
    this.sidebar.update(() => {
      const res: SidebarCheckList[] = [];

      for (const sub of sidebarData?.subcategories ?? []) {
        res.push(this.#setUpSidebarChecklistArray(sub, sub.sons, categoryId));
      }

      if (isFirstTime)
        this.#sidebarPersistency.update(() => [...res]);

      return [...res];
    });
  }

  #setUpSidebarChecklistArray(sub: any, sublist: any[], categoryId: number, filtering: boolean = false) {
    return {
      id: sub.id,
      name: sub.name.trim(),
      checked: false,
      key: `${categoryId}-${sub.id}`,
      sublist: sublist?.map((sublist) => {
        const sublistson = filtering ? sublist.sublist : sublist.subCategories;

        sublist.name = sublist.name.trim();
        sublist.checked = false;
        sublist.key = `${categoryId}-${sub.id}-${sublist.id}`;
        sublist.sublist = sublistson?.map((sub_s: any) => {
          sub_s.name = sub_s.name.trim();
          sub_s.checked = false;
          sub_s.key = `${sub.id}-${sublist.id}-${sub_s.id}`;
          return sub_s;
        }) || []
        return sublist;
      }) || []
    } as SidebarCheckList
  }

  setUpSidebarDataAfterFilter(products: any, clear: boolean = false) {
    // If clear is true, reset the sidebar by making all items visible and return
    if (clear) {
      return this.sidebar()?.forEach(item => {
        item.hidden = false;
        item.sublist?.forEach(sublit => sublit.hidden = false);
      });
    }

    this.sidebar()?.forEach(item => {
      let itemHasMatch = false;

      item.sublist?.forEach(son => {
        // Check if at least one product matches the child subcategory
        const sonMatch = products.some((product: any) => product.subcategorySon?.id === son.id);
        son.hidden = !sonMatch;

        if (sonMatch)
          itemHasMatch = true; // If a child matches, the parent should also be shown
      });

      // Check if at least one product matches the parent subcategory,
      // or if a child has already matched
      const itemMatch = itemHasMatch || products.some((product: any) => product.subcategory?.id === item.id);

      // Hide the item if neither the parent nor any child matches
      item.hidden = !itemMatch;
    });
  }



  goToCategory(item: SidebarCheckList) {
    if (!item) return;
    this.#categoryToNavigate.next(item);
  }



}
